import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg2">
          <div className="contact">
            <div className="wrapper contact">
              <header
                style={{
                  height: "120px"
                }}
              >
                <div
                  style={{
                    height: "67px"
                  }}
                >
                  <h1 className="logo">
                    <a href="/" />
                  </h1>
                  <nav>
                    <a href="/site/produkty/" className="link">
                      Produkty
                    </a>
                    <span>| </span>
                    <a
                      href="/site/o-neutrogenie/kim-jestesmy/"
                      className="link"
                    >
                      O Neutrogenie
                    </a>
                    <span>| </span>
                    <a href="/site/bad-piekna/" className="link">
                      Bądź piękna
                    </a>
                    <span>| </span>
                    <a href="/site/see-whats-possible/" className="link">
                      See What's Possible
                    </a>
                    <span>| </span>
                    {/* <a
                      href="/site/skora-z-niedoskonalosciami/"
                      className="link"
                    >
                      Skóra z niedoskonałościami
                    </a>
                    <span>| </span> */}
                  </nav>
                </div>
                <div
                  style={{
                    "padding-left": "185px",
                    height: "53px"
                  }}
                >
                  <nav
                    className="second"
                    style={{
                      float: "none"
                    }}
                  >
                    <a href="/site/hydroboost/" className="link">
                      Hydro Boost
                    </a>
                   {/* <span>| </span>
                    <a href="/site/skin-detox/" className="link">
                      Skin Detox
                    </a>
                    <span>| </span>
                    <a href="/site/cellular-boost/" className="link">
                      Cellular Boost
                    </a> */}
                    <span />
                  </nav>
                </div>
              </header>
              <div className="contaCtcontent"></div>
            </div>
            <div className="wrapper">
              <div className="content">
                <section className="main">
                  <div className="text full">
                    <div>
                      <h2>Zachęcamy do kontaktu</h2>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br /> <br />
                      <table
                        style={{
                          width: "960px",
                          border: "0px solid #cc339b",
                          height: "108px"
                        }}
                        border="0"
                        cellSpacing="30"
                        cellPadding="10"
                        align="center"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <h4>
                                <span>
                                  Dystrybutor:
                                  <br />
                                  <br />
                                </span>
                              </h4>
                              <p>JNTL Consumer Health (Poland) sp. z o.o.</p>
                              <p>ul. Iłżecka 24</p>
                              <p>02-135 Warszawa</p>
                              <p>Tel.: +48 (22) 237 80 00&nbsp;&nbsp;</p>
                              <p>Fax: +48 (22) 237 80 01</p>
                            </td>
                            <td>
                              <h4>
                                <span className="s1">
                                  Zapytania konsumenckie:
                                  <br />
                                  <br />
                                </span>
                              </h4>
                              <p>
                                Proszę kliknij&nbsp;
                                <a
                                  href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p4"
                                  target="_blank"
                                >
                                  tutaj
                                </a>
                                , aby skontaktować się z nami poprzez formularz
                                kontaktowy.
                              </p>
                              <p>&nbsp;</p>
                              <p>infolinia: 00&nbsp;800&nbsp;555 22&nbsp;000</p>
                              <p>
                                (pon.- pt. w godz. 9-17 opłata jak za 1 impuls
                                taryfy lokalnej)
                              </p>
                              <p>&nbsp;</p>
                              <p>
                              Inspektor Ochrony Danych w UE ds Kenvue: Cristina del Val Gómez e-mail:&nbsp;<a href="mailto:emeaprivacy@kenvue.com">Kenvue EMEA Privacy</a>
                              </p>
                              <p>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp;
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        <span>&nbsp;</span>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="wrapper">
            <nav>
              <a href="/site/" className="logo2" />
              <a href="/site/nota-prawna/">Nota Prawna</a>
              <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
               <a href="/site/cookies/">Polityka cookies</a>
              <a href="/site/kontakt/">Zachęcamy do kontaktu</a>
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
            <p>
              © JNTL Consumer Health (Poland) sp. z o.o., 2023. Ostatnia aktualizacja 01.08.2023<br></br>
Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000937574, kapitał zakładowy w wysokości 107.357.450 PLN, NIP 5272980564. JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną odpowiedzialność za treści zawarte na niniejszej stronie. Strona jest przeznaczona dla użytkowników z Polski.<br></br>
Niniejsza witryna zawiera łącza do witryn, które nie podlegają naszej polityce prywatności. Zachęcamy do zapoznawania się z politykami prywatności każdej odwiedzanej witryny.
              <br />{" "}
            </p>{" "}
            <p>
              <span>
                Strona została zoptymalizowana pod kątem przeglądarek: Internet
                Explorer 7 i wyższych, Mozilla Firefox, Google Chrome, Opera,
                Safari
              </span>{" "}
              <span className="right">
                Zalecana rozdzielczość: 1024 x 768 pikseli
              </span>{" "}
            </p>
          </div>
        </footer>
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
          }}
        />
        
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
          }}
        />
      </div>
    );
  }
}

export default Page;
